import { ShipmentsListFilters, SortableField } from "constants/shipments";
import { SortDirection } from "react-lib";
import { TableColumn } from "routes/Shipments/ShipmentsList/Table/Table.constants";

export enum ReferenceNumberTypes {
  REF = "REF",
  CTR = "CTR",
  FLT_VOY = "FLT/VOY",
  VESSEL = "VESSEL",
  CRN = "CRN",
}

export type ActiveSwappableColumn =
  | TableColumn.SHIPPER
  | TableColumn.CONSIGNEE
  | TableColumn.CARRIER
  | TableColumn.CUSTOMER;
export interface ShipmentsState {
  listFilters: ShipmentsListFilters;
  scrollPosition: number;
  visibleReferenceNumbers: Partial<Record<ReferenceNumberTypes, boolean>>;
  activeSwappableColumn: ActiveSwappableColumn;
}

export const initialState: ShipmentsState = {
  listFilters: {
    order: [
      { field: SortableField.PICKUP_COMBINED, order: SortDirection.DESCENDING },
    ],
    limit: 20,
    offset: 0,
  },
  visibleReferenceNumbers: {},
  scrollPosition: 0,
  activeSwappableColumn: TableColumn.SHIPPER,
};

export enum ShipmentsActionType {
  SET_SHIPMENTS_LIST_FILTERS = "SHIPMENTS/SET_SHIPMENTS_LIST_FILTERS",
  SET_SHIPMENTS_LIST_QUERY = "SHIPMENTS/SET_SHIPMENTS_LIST_QUERY",
  SET_SCROLL_POSITION = "SHIPMENTS/SET_SCROLL_POSITION",
  SET_LIST_SORT = "SHIPMENTS/SET_LIST_SORT",
  SET_VISIBLE_REFERENCE_NUMBERS = "SHIPMENTS/SET_VISIBLE_REFERENCE_NUMBERS",
  SET_ACTIVE_SWAPPABLE_COLUMN = "SHIPMENTS/SET_ACTIVE_SWAPPABLE_COLUMN",
}

export type ShipmentsAction =
  | {
      type: ShipmentsActionType.SET_SHIPMENTS_LIST_FILTERS;
      listFilters: ShipmentsListFilters;
    }
  | {
      type: ShipmentsActionType.SET_SHIPMENTS_LIST_QUERY;
      query?: string;
    }
  | {
      type: ShipmentsActionType.SET_SCROLL_POSITION;
      scrollPosition: number;
    }
  | {
      type: ShipmentsActionType.SET_LIST_SORT;
      field: SortableField;
      order: SortDirection;
    }
  | {
      type: ShipmentsActionType.SET_VISIBLE_REFERENCE_NUMBERS;
      visibleReferenceNumbers: Partial<Record<ReferenceNumberTypes, boolean>>;
    }
  | {
      type: ShipmentsActionType.SET_ACTIVE_SWAPPABLE_COLUMN;
      activeSwappableColumn: ActiveSwappableColumn;
    };
