export enum NotificationRouteTypesEnum {
  TRADE_LANE = "TRADE_LANE",
  INBOUND = "INBOUND",
  OUTBOUND = "OUTBOUND",
}

export enum NotificationSettingEnum {
  General = "GENERAL",
  Custom = "CUSTOM",
}

export enum NotificationFollowTypeEnum {
  All = "ALL",
  Favorites = "FAVORITES",
}

export enum LocationTypeEnum {
  COUNTRY = "COUNTRY",
  PLACE = "PLACE",
}
