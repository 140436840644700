import { EventCode, MilestoneTranslationPrefix } from "constants/shipments";
import { useQuery } from "@apollo/client";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import messages from "../../src/localeData/en.json";
import apiKeys from "../constants/apiKeys";
import { UserRoles } from "../constants/userRoles";
import { useLanguage } from "../containers/Translations/Translations.context";
import { GET_USER_DETAILS } from "../queries/AAA/userQueries";
import { RootState } from "../redux/reducers";
import useCorporatePartnerUUID from "./useCorporatePartnerUUID";
import useDomElementObserver from "./useDomElementObserver";

const vaExactRoutesList = [
  "/",
  "/shipments",
  "/orders",
  "/order-lines",
  "/cdz",
  "/quotes",
  "/bookings",
  "/booking-drafts",
  "/analytics/spend",
  "/reporting",
  "/reporting/your-reports",
  "/settings/account",
  "/settings/notifications",
  "/reporting/archive",
  "/settings/portal",
];

const vaRoutesStartsWithList = [
  "/get-a-quote",
  "/make-a-booking",
  "/offers",
  "/booking/",
  "/analytics",
  "/support",
];

const vaIframeObserverConfig = {
  observeAttributes: true,
  attributeName: "id",
  observeChildList: true,
  childId: "dhl-va-widget-iframe",
};

const bodyStyleAttributeValueObserverConfig = {
  observeAttributes: true,
  attributeName: "style",
};

const useVirtualAssistant = () => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const virtualAssistantInitiated = useRef(false);
  const { isUserLoggedIn, loggedInUserRoles, userId } = useSelector(
    (state: RootState) => state.auth
  );
  const { data: userData } = useQuery(GET_USER_DETAILS, {
    skip: !isUserLoggedIn,
  });
  const userHasVaSubService = loggedInUserRoles?.includes(
    UserRoles.VIRTUAL_ASSISTANT
  );
  const language = useLanguage();
  const location = useLocation();
  const corporatePartner = useCorporatePartnerUUID() || "";

  const isSupportPage = useMemo(
    () => location.pathname.startsWith("/support"),
    [location.pathname]
  );

  const milestoneKeys = Object.keys(EventCode);

  const milestonesDictionary = milestoneKeys.map((key) => ({
    [key]:
      messages[
        `${MilestoneTranslationPrefix.MILESTONE}${key}` as keyof typeof messages
      ] ?? key,
  }));

  const initWidget = useCallback(() => {
    window.dhlVAWidget.init({
      accessToken: apiKeys.virtualAssistant || "",
      personality: "dgf-mydhli-va",
      availableLanguageCodes: isSupportPage ? [] : ["en"],
      hidden: false,
      customParameters: JSON.stringify({
        idToken: localStorage.getItem("id_token") || "",
        companyName: userData?.user?.companyName || "",
        countryName: userData?.user?.address?.country?.name || "",
        cpCode: corporatePartner || "",
        countryCode: userData?.user?.address?.country?.code || "",
        userId: userId || "",
        translations: milestonesDictionary,
      }),
    });
  }, [
    corporatePartner,
    isSupportPage,
    milestonesDictionary,
    userData?.user?.address?.country?.code,
    userData?.user?.address?.country?.name,
    userData?.user?.companyName,
    userId,
  ]);

  const { childAdded: vaIframeAdded, observedChild } = useDomElementObserver(
    vaIframeObserverConfig
  );

  const hideWidget = useCallback(
    (hidden: boolean) => {
      if (observedChild) {
        observedChild.style.display = hidden ? "none" : "block";
      }
    },
    [observedChild]
  );

  const { attributeValue: bodyStyleAttributeValue } = useDomElementObserver(
    bodyStyleAttributeValueObserverConfig
  );

  useEffect(() => {
    virtualAssistantInitiated.current = false;
  }, [corporatePartner]);

  useEffect(() => {
    if (
      virtualAssistantInitiated.current &&
      location.pathname &&
      vaIframeAdded
    ) {
      hideWidget(
        (!vaExactRoutesList.includes(location?.pathname) &&
          !vaRoutesStartsWithList.some((route) =>
            location.pathname.startsWith(route)
          )) ||
          !!bodyStyleAttributeValue ||
          (language !== "en" && !isSupportPage)
      );
    }
  }, [
    bodyStyleAttributeValue,
    vaIframeAdded,
    isScriptLoaded,
    location.pathname,
    userId,
    hideWidget,
    language,
    isSupportPage,
    corporatePartner,
  ]);

  useEffect(() => {
    if (
      userHasVaSubService &&
      userData &&
      apiKeys.virtualAssistant &&
      (language === "en" || isSupportPage)
    ) {
      if (!isScriptLoaded) {
        const handleLoad = () => {
          const script = document.createElement("script");
          script.type = "text/javascript";
          script.async = true;
          script.setAttribute("id", "dhl-va-widget");
          script.src = ["local", "development", "test", "uat"].includes(
            apiKeys.environment
          )
            ? "https://vawidget.dhl.com/beta/embed.js"
            : "https://vawidget.dhl.com/latest/embed.js";

          document.body.appendChild(script);

          script.onload = () => {
            setIsScriptLoaded(true);
          };

          document.body.appendChild(script);
        };

        if (document.readyState === "complete") {
          handleLoad();
        } else {
          window.addEventListener("load", handleLoad);

          return () => {
            window.removeEventListener("load", handleLoad);
          };
        }
      }
    }
  }, [
    isScriptLoaded,
    isSupportPage,
    language,
    location.pathname,
    userData,
    userHasVaSubService,
  ]);

  useEffect(() => {
    if (
      isScriptLoaded &&
      !virtualAssistantInitiated.current &&
      userId &&
      (vaExactRoutesList.includes(location?.pathname) ||
        vaRoutesStartsWithList.some((route) =>
          location.pathname.startsWith(route)
        ))
    ) {
      initWidget();
      virtualAssistantInitiated.current = true;
    }
  }, [
    isScriptLoaded,
    initWidget,
    userData,
    userId,
    location.pathname,
    corporatePartner,
  ]);

  useEffect(() => {
    if (location?.pathname && !isUserLoggedIn) {
      hideWidget(true);
    }
  }, [hideWidget, language, isUserLoggedIn, location?.pathname]);
};

export default useVirtualAssistant;
