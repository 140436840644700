import { FeatureFlagName } from "constants/getConfigClient";
import { useMDMmyDHLiFeatureFlags } from "./masterDataManagement";

const useMyDHLiFeatureFlags = ():
  | Record<FeatureFlagName, boolean>
  | undefined => {
  const { data, loading } = useMDMmyDHLiFeatureFlags();

  if (loading) {
    return undefined;
  }

  return data?.getMyDHLiFeatureFlags.reduce(
    (flagsState, featureFlag) => ({
      ...flagsState,
      [featureFlag.name]: featureFlag.isEnabled,
    }),
    {} as Record<FeatureFlagName, boolean>
  );
};

export default useMyDHLiFeatureFlags;
