/* eslint-disable import/order */
import ContentWrapper from "components/atoms/ContentWrapper";
import FullpageLoader from "components/molecules/FullpageLoader";
import OfflineBanner from "components/molecules/OfflineBanner";
import ErrorPopup from "components/organisms/ErrorPopup/ErrorPopup";
import Nav from "components/organisms/Nav";
import TermsAndConditionsModal from "components/organisms/TermsAndConditionsModal";
import Alerts from "components/templates/Alerts/Alerts";
import getConfigClient, { FeatureFlagName } from "constants/getConfigClient";
import { checkIfNoValidTokensExist } from "helpers/tokenHelper";
import { useOnAccountTypeSwitch } from "hooks";
import useDocumentTitle from "hooks/useDocumentTitle";
import { useNavHelper } from "hooks/useNavHelper";
import "moment/locale/cs";
import "moment/locale/de";
import "moment/locale/el";
import "moment/locale/es";
import "moment/locale/et";
import "moment/locale/fr";
import "moment/locale/hr";
import "moment/locale/ja";
import "moment/locale/ko";
import "moment/locale/lt";
import "moment/locale/lv";
import "moment/locale/nb";
import "moment/locale/nl-be";
import "moment/locale/pl";
import "moment/locale/pt";
import "moment/locale/ro";
import "moment/locale/sl";
import "moment/locale/sr";
import "moment/locale/tr";
import "moment/locale/uk";
import "moment/locale/vi";
import "moment/locale/zh-cn";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Route, Switch, useLocation } from "react-router-dom";
import AnalyticsRenderer from "routes/Analytics/AnalyticsRenderer";
import ErrorPage from "routes/Error";
import Login from "routes/Login";
import Logout from "routes/Logout";
import { SCREEN_SIZES } from "utils/css";
import RedirectToTrackMessage from "./components/molecules/RedirectToTrackMessage";
import Tracker from "./components/organisms/Tracker";
import CookieSettingsLink from "./CookieSettingsLink";
import trackRedirectHelper from "./helpers/trackRedirectHelper";
import InitializeQnB from "./InitializeQnB";
import loadable from "@loadable/component";
import { NotificationSettingEnum } from "constants/notifications";
import NotificationsSettingsContextProvider from "./containers/NotificationsSettingsContext";
import useAdobeAnalytics from "hooks/useAdobeAnalytics";
import { makeABookingPath } from "./routes/MakeABooking/MakeABooking.constants";
import {
  RouteWithTestingContext,
  TestingContextProvider,
} from "./containers/TestingContext/TestingContext";
import { Feature } from "./containers/TestingContext/TestingContext.constants";
import { purchaseOrdersListsPaths } from "routes/PurchaseOrders/PurchaseOrdersLists/PurchaseOrdersLists.constants";
import { digestDownloadPath } from "routes/Notifications/DigestDownload/DigestDownload";
import { reportingDownloadPath } from "routes/Reporting/ReportingDownload";
import { PurchaseOrderDetailsPaths } from "routes/PurchaseOrders/PurchaseOrders.constants";
import ReadOnlyWrapper from "containers/ReadOnlyWrapper";
import { getReadOnly } from "redux/userSettings/selectors";
import { ProtectedRoute } from "components/ProtectedRoute/ProtectedRoute";
import NPSSurvey from "components/NPSSurvey/NPSSurvey";
import { ShipmentsRoutes, SupportCenterRoutes } from "./constants/paths";
import { notificationsUnsubscribePath } from "routes/Notifications/NotificationsUnsubscribe/NotificationsUnsubscribe.constants";
import useVirtualAssistant from "./hooks/useVirtualAssistant";
import useAcousticsCampaign from "./hooks/useAcousticsCampaign";
import { logOut } from "redux/auth/actions";
import isCurrentPathUnprotected from "utils/isCurrentPathUnprotected";
import ToastNotification from "components/ToastNotification/ToastNotification";
import useIsFeatureAvailable from "hooks/useIsFeatureAvailable";
import { Service } from "components/templates/Alerts/Alerts.constants";

const NotFoundPage = loadable(
  () => import("./components/organisms/NotFoundPage/NotFoundPage"),
  {
    fallback: <FullpageLoader />,
  }
);
const BookingPageWrapper = loadable(
  () => import("./routes/BookingPage/BookingPageWrapper"),
  {
    fallback: <FullpageLoader />,
  }
);
const ContinueWithQuoteRedirect = loadable(
  () => import("./routes/BookingPage/ContinueWithQuoteRedirect"),
  {
    fallback: <FullpageLoader />,
  }
);
const PreviewTemplate = loadable(
  () => import("./routes/PreviewTemplate/PreviewTemplate"),
  {
    fallback: <FullpageLoader />,
  }
);
const Quotes = loadable(() => import("./routes/Quotes/Quotes"), {
  fallback: <FullpageLoader />,
});
const Reporting = loadable(() => import("./routes/Reporting/Reporting"), {
  fallback: <FullpageLoader />,
});
const ReportingDownload = loadable(
  () => import("./routes/Reporting/ReportingDownload"),
  {
    fallback: <FullpageLoader />,
  }
);
const Bookings = loadable(() => import("./routes/Bookings/Bookings"), {
  fallback: <FullpageLoader />,
});
const PurchaseOrdersLists = loadable(
  () =>
    import("./routes/PurchaseOrders/PurchaseOrdersLists/PurchaseOrdersLists"),
  {
    fallback: <FullpageLoader />,
  }
);
const CDZData = loadable(() => import("./routes/CDZ/CdzData"), {
  fallback: <FullpageLoader />,
});
const GetAQuotePage = loadable(() => import("./routes/GetAQuotePage/index"), {
  fallback: <FullpageLoader />,
});
const OffersPage = loadable(() => import("./routes/OffersPage/index"), {
  fallback: <FullpageLoader />,
});
const ReportingEdit = loadable(
  () => import("./routes/ReportingEdit/ReportingEdit"),
  {
    fallback: <FullpageLoader />,
  }
);
const ReportingUnsubscribe = loadable(
  () => import("./routes/ReportingUnsubsribe/ReportingUnsubscribe"),
  {
    fallback: <FullpageLoader />,
  }
);
const TermsAndConditionsPage = loadable(
  () => import("./routes/TermsAndConditionsPage"),
  {
    fallback: <FullpageLoader />,
  }
);
const Account = loadable(() => import("./routes/Account/Account"), {
  fallback: <FullpageLoader />,
});
const NotificationsSettings = loadable(
  () =>
    import(
      "./routes/Notifications/NotificationsSettings/NotificationsSettings"
    ),
  {
    fallback: <FullpageLoader />,
  }
);
const NotificationDetails = loadable(
  () =>
    import("./routes/Notifications/NotificationDetails/NotificationDetails"),
  {
    fallback: <FullpageLoader />,
  }
);
const CreateNewCustomNotification = loadable(
  () =>
    import(
      "./routes/Notifications/CreateNewCustomNotification/CreateNewCustomNotification"
    ),
  {
    fallback: <FullpageLoader />,
  }
);
const DigestDownload = loadable(
  () => import("./routes/Notifications/DigestDownload/DigestDownload"),
  {
    fallback: <FullpageLoader />,
  }
);
const PortalSettings = loadable(
  () => import("./routes/PortalSettings/PortalSettings"),
  {
    fallback: <FullpageLoader />,
  }
);
const NotificationsUnsubscribe = loadable(
  () =>
    import(
      "./routes/Notifications/NotificationsUnsubscribe/NotificationsUnsubscribe"
    ),
  {
    fallback: <FullpageLoader />,
  }
);

const MakeABooking = loadable(
  () => import("./routes/MakeABooking/MakeABooking"),
  {
    fallback: <FullpageLoader />,
  }
);

const EmailLinkRedirectPage = loadable(
  () => import("./routes/EmailLinkRedirectPage/EmailLinkRedirectPage"),
  {
    fallback: <FullpageLoader />,
  }
);

const PurchaseOrder = loadable(
  () => import("./routes/PurchaseOrders/PurchaseOrder/PurchaseOrder"),
  {
    fallback: <FullpageLoader />,
  }
);

const PurchaseOrderLine = loadable(
  () => import("./routes/PurchaseOrders/PurchaseOrderLine/PurchaseOrderLine"),
  {
    fallback: <FullpageLoader />,
  }
);

const ShipmentsList = loadable(
  () => import("./routes/Shipments/ShipmentsList/ShipmentsList"),
  {
    fallback: <FullpageLoader />,
  }
);

const ShipmentDetails = loadable(
  () => import("./routes/Shipments/ShipmentDetails/ShipmentDetails"),
  {
    fallback: <FullpageLoader />,
  }
);

const PrintShipmentDetails = loadable(
  () => import("./routes/Shipments/PrintShipmentDetails/PrintShipmentDetails"),
  {
    fallback: <FullpageLoader />,
  }
);

const SupportCenterLandingPage = loadable(
  () =>
    import(
      "./routes/CustomerSupportCenter/SupportCenterLandingPage/SupportCenterLandingPage"
    ),
  {
    fallback: <FullpageLoader />,
  }
);

const SupportCenterFAQCategoryPage = loadable(
  () =>
    import(
      "./routes/CustomerSupportCenter/SupportCenterFAQCategoryPage/SupportCenterFAQCategoryPage"
    ),
  {
    fallback: <FullpageLoader />,
  }
);

const SupportCenterGlossary = loadable(
  () =>
    import(
      "./routes/CustomerSupportCenter/SupportCenterGlossary/SupportCenterGlossary"
    ),
  {
    fallback: <FullpageLoader />,
  }
);

const SupportCenterVideos = loadable(
  () =>
    import(
      "./routes/CustomerSupportCenter/SupportCenterVideos/SupportCenterVideos"
    ),
  {
    fallback: <FullpageLoader />,
  }
);

const SupportCenterSearchResultsPage = loadable(
  () =>
    import(
      "./routes/CustomerSupportCenter/SupportCenterSearchResultsPage/SupportCenterSearchResultsPage"
    ),
  {
    fallback: <FullpageLoader />,
  }
);

const Dashboard = loadable(() => import("./routes/Dashboard/Dashboard"), {
  fallback: <FullpageLoader />,
});

const App = () => {
  const noValidTokenExists = checkIfNoValidTokensExist();
  useVirtualAssistant();
  const location = useLocation();
  const shouldScrollNavMenu = useMediaQuery({ maxHeight: "450px" });
  const belowLg = useMediaQuery({ maxWidth: SCREEN_SIZES.until.lg });
  let navCollapsed = useSelector((state) => state.app.navCollapsed);
  const isReadOnly = useSelector(getReadOnly);
  if (!belowLg) {
    navCollapsed = true;
  }
  const { hideNav, validPage, shouldNotRenderNav, isQuoteRoute } = useNavHelper(
    location?.pathname
  );
  trackRedirectHelper(location);
  useOnAccountTypeSwitch();
  useDocumentTitle(isQuoteRoute);
  useAdobeAnalytics();
  useAcousticsCampaign();

  const dispatch = useDispatch();

  const loggedInUserEmail = useSelector(
    (state) => state.auth.loggedInUserEmail
  );

  useEffect(() => {
    if (
      loggedInUserEmail !== "" &&
      noValidTokenExists &&
      !location.pathname.includes("logout") &&
      !location.pathname.includes("login")
    ) {
      dispatch(logOut({}, isCurrentPathUnprotected(location.pathname)));
    }
  }, [noValidTokenExists, dispatch, location.pathname, loggedInUserEmail]);

  const isNPSSurveyAvailable =
    useIsFeatureAvailable(FeatureFlagName.NPS) && loggedInUserEmail !== "";

  if (window.redirectingToTrack) {
    return <RedirectToTrackMessage />;
  } else
    return (
      <ReadOnlyWrapper>
        {shouldNotRenderNav || !validPage ? null : (
          <TestingContextProvider feature={Feature.APP}>
            <Nav
              hideNav={hideNav}
              shouldScrollNavMenu={shouldScrollNavMenu}
              belowLg={belowLg}
            />
          </TestingContextProvider>
        )}
        {!shouldNotRenderNav && <TermsAndConditionsModal belowLg={belowLg} />}
        <ContentWrapper
          className="outerWrapper"
          isNavBarCollapsed={navCollapsed}
          isNavBarVisible={!hideNav}
          isTopBarVisible={!shouldNotRenderNav}
        >
          <Switch>
            <RouteWithTestingContext
              feature={Feature.DASHBOARD}
              exact
              path="/"
              component={Dashboard}
            />
            <RouteWithTestingContext
              feature={Feature.BOOKINGS}
              path={["/bookings", "/booking-drafts"]}
              component={Bookings}
            />
            <RouteWithTestingContext
              feature={Feature.ORDERS}
              path={purchaseOrdersListsPaths}
              component={PurchaseOrdersLists}
              exact
              strict
            />
            <RouteWithTestingContext
              feature={Feature.QUOTES}
              path="/quotes"
              component={Quotes}
            />
            <RouteWithTestingContext
              feature={Feature.ANALYTICS}
              path="/analytics"
              component={AnalyticsRenderer}
            />
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />
            <Route path="/settings/account" component={Account} />
            {getConfigClient("FEATURE_CDZ") && (
              <Route path="/cdz" component={CDZData} />
            )}
            <ProtectedRoute
              condition={!isReadOnly}
              exact
              path="/settings/notifications/general/:id"
            >
              <NotificationDetails type={NotificationSettingEnum.General} />
            </ProtectedRoute>
            <ProtectedRoute
              condition={!isReadOnly}
              exact
              path="/settings/notifications/custom/new"
              component={CreateNewCustomNotification}
            >
              <CreateNewCustomNotification />
            </ProtectedRoute>
            <ProtectedRoute
              condition={!isReadOnly}
              exact
              path="/settings/notifications/custom/:id"
            >
              <NotificationsSettingsContextProvider>
                <NotificationDetails type={NotificationSettingEnum.Custom} />
              </NotificationsSettingsContextProvider>
            </ProtectedRoute>
            <ProtectedRoute
              condition={!isReadOnly}
              exact
              path="/settings/notifications"
            >
              <NotificationsSettingsContextProvider>
                <NotificationsSettings />
              </NotificationsSettingsContextProvider>
            </ProtectedRoute>
            <Route path={digestDownloadPath} component={DigestDownload} />
            <RouteWithTestingContext
              feature={Feature.BOOKINGS}
              path={notificationsUnsubscribePath}
              component={NotificationsUnsubscribe}
            />
            <Route exact path="/settings/portal" component={PortalSettings} />
            <Route path="/error" component={ErrorPage} />
            <ProtectedRoute condition={!isReadOnly} path="/get-a-quote">
              <GetAQuotePage />
            </ProtectedRoute>
            <Route path={["/offers/:id", "/offers"]} component={OffersPage} />
            <Route
              path="/continue-with-quote/:id"
              component={ContinueWithQuoteRedirect}
            />
            <ProtectedRoute
              condition={!isReadOnly}
              path={["/booking", "/continue-with-quote"]}
              component={BookingPageWrapper}
            />
            <Route
              path="/terms-and-conditions"
              component={TermsAndConditionsPage}
            />
            <ProtectedRoute
              path="/reporting/edit/:templateName/:viewId"
              condition={!isReadOnly}
            >
              <RouteWithTestingContext
                feature={Feature.REPORTING}
                path="/reporting/edit/:templateName/:viewId"
                component={ReportingEdit}
              />
            </ProtectedRoute>
            <ProtectedRoute
              path="/reporting/preview/:viewId"
              condition={!isReadOnly}
            >
              <RouteWithTestingContext
                feature={Feature.REPORTING}
                path="/reporting/preview/:viewId"
                component={PreviewTemplate}
              />
            </ProtectedRoute>
            <ProtectedRoute
              path="/reporting/unsubscribe/:viewName/:token"
              condition={!isReadOnly}
            >
              <RouteWithTestingContext
                feature={Feature.REPORTING}
                path="/reporting/unsubscribe/:viewName/:token"
                component={ReportingUnsubscribe}
              />
            </ProtectedRoute>
            <RouteWithTestingContext
              feature={Feature.REPORTING}
              path="/reporting"
              component={Reporting}
            />
            <RouteWithTestingContext
              feature={Feature.REPORTING}
              path={reportingDownloadPath}
              component={ReportingDownload}
            />
            <ProtectedRoute condition={!isReadOnly} path={makeABookingPath}>
              <RouteWithTestingContext
                feature={Feature.BOOKING_STANDALONE}
                path={makeABookingPath}
                component={MakeABooking}
              />
            </ProtectedRoute>
            <RouteWithTestingContext
              feature={Feature.ORDERS}
              path={PurchaseOrderDetailsPaths.ORDERS}
              component={PurchaseOrder}
            />
            <RouteWithTestingContext
              feature={Feature.ORDERS}
              path={PurchaseOrderDetailsPaths.ORDER_LINES}
              component={PurchaseOrderLine}
            />
            <RouteWithTestingContext
              exact
              feature={Feature.SHIPMENTS}
              path={ShipmentsRoutes.SHIPMENTS}
              component={ShipmentsList}
            />
            <ProtectedRoute
              path={ShipmentsRoutes.PRINT_SHIPMENT_DETAILS}
              condition={!isReadOnly}
            >
              <RouteWithTestingContext
                feature={Feature.SHIPMENTS}
                path={ShipmentsRoutes.PRINT_SHIPMENT_DETAILS}
                component={PrintShipmentDetails}
              />
            </ProtectedRoute>
            <RouteWithTestingContext
              feature={Feature.SHIPMENTS}
              path={ShipmentsRoutes.SHIPMENT_DETAILS}
              component={ShipmentDetails}
            />
            <RouteWithTestingContext
              exact
              feature={Feature.CUSTOMER_SUPPORT_CENTER}
              path={SupportCenterRoutes.DASHBOARD}
              component={SupportCenterLandingPage}
            />
            <RouteWithTestingContext
              feature={Feature.CUSTOMER_SUPPORT_CENTER}
              path={SupportCenterRoutes.FAQ_CATEGORY_PAGE}
              component={SupportCenterFAQCategoryPage}
            />
            <RouteWithTestingContext
              exact
              feature={Feature.CUSTOMER_SUPPORT_CENTER}
              path={SupportCenterRoutes.GLOSSARY}
              component={SupportCenterGlossary}
            />
            <RouteWithTestingContext
              exact
              feature={Feature.CUSTOMER_SUPPORT_CENTER}
              path={SupportCenterRoutes.VIDEOS}
              component={SupportCenterVideos}
            />
            <RouteWithTestingContext
              feature={Feature.CUSTOMER_SUPPORT_CENTER}
              path={SupportCenterRoutes.SEARCH_RESULTS}
              component={SupportCenterSearchResultsPage}
            />

            <RouteWithTestingContext
              feature={Feature.APP}
              path="/email-link/:uuid"
            >
              {({ match }) => (
                <EmailLinkRedirectPage uuid={match.params.uuid} />
              )}
            </RouteWithTestingContext>
            <RouteWithTestingContext
              feature={Feature.APP}
              path="*"
              render={() => <NotFoundPage />}
            />
          </Switch>
        </ContentWrapper>
        <OfflineBanner isMobile={belowLg} />
        <ErrorPopup />
        <Tracker />
        <InitializeQnB pathname={location?.pathname} />
        {
          /*
            TODO: Backend Alert Service will support separate ids for each service (NOQT, BOOK, DASHBOARD, SHIPMENTS, REPORTS),
            but in initial configuration there will be only NOQT for Q+B specific alerts and PORTAL for the rest of the portal.
          */
          <Alerts service={isQuoteRoute ? Service.NOQT : Service.PORTAL} />
        }
        <CookieSettingsLink />
        <ToastNotification />
        {isNPSSurveyAvailable && <NPSSurvey />}
      </ReadOnlyWrapper>
    );
};

export default App;
