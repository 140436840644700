export enum FilterRelation {
  CONJUNCTION = "conjunction",
  DISJUNCTION = "disjunction",
}

// values need to match BE values, so they can't be lower case
export enum TemplateFieldFlag {
  AUTOCOMPLETE = "AUTOCOMPLETE",
  NO_SEARCH = "NO_SEARCH",
  NO_SORT = "NO_SORT",
  NO_DISPLAY = "NO_DISPLAY",
  CLOB = "CLOB",
}

// values need to match BE values, luckily they are lower case
export const FileFormats = [
  { label: "XLSX", value: "xlsx" },
  { label: "CSV", value: "csv" },
];

// values need to match BE values, so they can't be lower case
export enum FieldType {
  TIMESTAMP = "TIMESTAMP",
  TEXT = "TEXT",
  INT = "INT",
  FLOAT = "FLOAT",
}

// values are matching attributes in filter_value used by BE
export enum DateRangeDirection {
  NEXT = "next",
  CURRENT = "current",
  PREVIOUS = "previous",
}

// values need to match BE values, so they can't be lower case
export enum DateRangeTimeUnit {
  DAYS = "DAYS",
  WEEKS = "WEEKS",
  MONTHS = "MONTHS",
  QUARTERS = "QUARTERS",
  YEARS = "YEARS",
}

export enum StringCondition {
  TAGS = "tags",
  EMPTY = "empty",
  NOT_EMPTY = "not empty",
  CONTAINS = "contains",
  NOT_CONTAINS = "not contains",
  STARTS_WITH = "starts with",
  ENDS_WITH = "ends with",
}

export interface ReportingField {
  __typename: "InnerField";
  name: string;
  targetName: null | string;
  displayName: null | string;
  description: null | string;
  groupName: null | string;
  isSearchable: boolean;
  isSortable: boolean;
  isAutocomplete: boolean;
  isDisplayable: boolean;
}

export interface ReportingColumn {
  __typename: "TemplateField";
  field: ReportingField;
  type: FieldType;
  restrictionFlags: TemplateFieldFlag[];
  floatPrecision: null | string;
  size: number;
  name: string;
  displayName: null | string;
  description: null | string;
  groupName: null | string;
  lcDisplayName: null | string;
}

interface ReportingFilterTimeRestriction {
  amount: number;
  timeUnit: DateRangeTimeUnit;
}

export interface ReportingFilterValue {
  type?: null | FieldType;
  empty?: null | boolean;
  not?: null | boolean;
  in?: null | string[];
  wildcard?: string | null;
  contains?: string | null;
  current?: null | ReportingFilterTimeRestriction;
  next?: null | ReportingFilterTimeRestriction;
  previous?: null | ReportingFilterTimeRestriction;
  gt?: null;
  gte?: null | string;
  lt?: null;
  lte?: null | string;
}

export interface ReportingWildcardFilterValue extends ReportingFilterValue {
  wildcard: string;
}

export interface ReportingFilter {
  field: string;
  // eslint-disable-next-line camelcase
  filter_group: null | number;
  // eslint-disable-next-line camelcase
  filter_value: ReportingFilterValue;
}

export interface BackendReportingFilter
  extends Omit<ReportingFilter, "filter_value"> {
  // eslint-disable-next-line camelcase
  filter_value: Partial<ReportingFilterValue>;
}

export interface PreviewReportingFilter {
  // This should allow ReportingFilterValue for all attributes but filterGroup
  filterGroup: number;
}

export interface ReportingFilterGroup {
  key: number;
  filters: ReportingFilter[];
}

export const goGreenIconName = "SvgGoGreen";

export interface ReportField {
  type: FieldType;
  name: string;
}

export interface FilterDragItem {
  groupIndex: number;
  filterIndex: number;
  filter: ReportingFilter;
}

type SortDirection = "ASC" | "DESC";

export interface Sort {
  order_by: string;
  order_direction: SortDirection;
}

export interface Column {
  description: string;
  displayName: string;
  groupName: string;
  name: string;
}

export type ColumnLookup = Map<string, ReportingColumn>;

export interface ReportingPreviewProps {
  columnLookup: ColumnLookup | null;
  templateName: string;
  columns: Column[];
  sorts: Sort;
  filters: ReportingFilter[];
  pageSize: number;
  initialPageSize?: number;
  onBeforeFetch?: () => void;
  onAfterFetch: () => void;
  refetch?: () => void;
}

export interface Rows {
  transportMode: string;
  housebill: string;
  transitStart: string;
  originName: string;
  originCountryCode: string;
  destinationName: string;
  destinationCountryCode: string;
  transitEnd: null;
  placeDeliveryCity: null;
  placeDeliveryCountry: null;
  delayStatus: string;
  consigneeName: string;
  consigneeReference: null;
  shipperName: string;
  shipperReference: null;
  productServiceCode: null;
  journeyDuration: null;
  carrierProcessingDays: null;
  weightChargeable: number;
  weightTotal: number;
  weightUnit: string;
  masterbill: null;
  transitStatus: string;
  commercialInvoiceNumbers: null;
  milestoneLobActual: null;
  milestoneLobEstimated: null;
  innerPieces: number;
  transportUnitTypeInternal: null;
  __typename: string;
}
