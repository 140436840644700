import { DirectionType, Product, ServiceLevel } from "./bookWorkflow";
import { FeatureFlagName } from "./getConfigClient";
import { AirFreightService } from "./quoteAndBook";

export interface FeatureFlag {
  name: FeatureFlagName;
  isEnabled: boolean;
}

export enum ExportType {
  FULL = "FULL",
  VALIDONLY = "VALIDONLY",
}

export interface ProductData {
  CODE: Product;
  LABEL: string;
  ICON: string;
  ORDER_NUM: number;
  LABEL_WEBLATE_KEY: "MODE_AFR" | "MODE_OFR";
  IS_ACTIVE_BOOKING: string;
  __typename: "ProductData";
}

export interface Country {
  validFrom: string;
  validTo: string | null;
  label: string;
  value: string;
  code: string;
  countryCode: string;
  is_european_union: string;
  phone: string;
  region: string;
  addressType: string;
  name_weblate_key: string;
  systemOfMeasurement: UnitSystem;
  __typename: "Country";
}

export interface CountryProduct {
  isOrigin: boolean;
  isBilling: boolean;
  isDestination: boolean;
  mailAvailability: string;
  productCode: string;
  countryCode: string;
  __typename: "CountryProduct";
}

export enum TerminalServiceType {
  FCL = "FCL",
  LCL = "LCL",
  LCL_FCL = "LCL/FCL",
}

export interface Terminal {
  label: string;
  value: string;
  code: string;
  countryCode: string;
  productCode: string;
  direction: DirectionType;
  pricingLocation: string;
  stationCode: string;
  serviceType: TerminalServiceType;
  name: string;
  cityName: string;
  unlocode: string;
  __typename: "Terminal";
}

export interface Region {
  CODE: string;
  NAME: string;
}

export enum PackageTypeIcon {
  CASE = "CASE",
  BAG = "BAG",
  PALETTE = "PALETTE",
  CARTON = "CARTON",
  CRATE = "CRATE",
  DRUM = "DRUM",
  TANK = "TANK",
  ROLL = "ROLL",
  SKELETON_BOX = "SKELETON_BOX",
  CTR_STD_20 = "CTR_STD_20",
  CTR_STD_40 = "CTR_STD_40",
  CTR_CUBE_40 = "CTR_CUBE_40",
  CTR_CUBE_45 = "CTR_CUBE_45",
}

export enum LooseCargoPackageType {
  CASE = "CASE",
  BAG = "BAG",
  PALLET = "PALLET",
  COMMON_PALLET_EUR_1 = "COMMON_PALLET_EUR_1",
  COMMON_PALLET_EUR_3 = "COMMON_PALLET_EUR_3",
  COMMON_PALLET_EUR_6 = "COMMON_PALLET_EUR_6",
  MY_PALLET_1 = "MY_PALLET_1",
  MY_PALLET_2 = "MY_PALLET_2",
  MY_PALLET_3 = "MY_PALLET_3",
  IN_PALLET_1 = "IN_PALLET_1",
  IN_PALLET_2 = "IN_PALLET_2",
  IN_PALLET_6 = "IN_PALLET_6",
  T_PALLET_11 = "T_PALLET_11",
  T_PALLET_12 = "T_PALLET_12",
  JP_PALLET_1 = "JP_PALLET_1",
  JP_PALLET_2 = "JP_PALLET_2",
  US_PALLET_1 = "US_PALLET_1",
  AU_PALLET_STD = "AU_PALLET_STD",
  AU_PALLET_EXP = "AU_PALLET_EXP",
  MX_PALLET_STD = "MX_PALLET_STD",
  MX_PALLET_EUR_1 = "MX_PALLET_EUR_1",
  MX_PALLET_CP_3 = "MX_PALLET_CP_3",
  BR_PALLET_EUR_3 = "BR_PALLET_EUR_3",
  CA_PALLET_EUR_1 = "CA_PALLET_EUR_1",
  CA_PALLET_EUR_3 = "CA_PALLET_EUR_3",
  CA_PALLET_EUR_6 = "CA_PALLET_EUR_6",
  CN_PALLET_1 = "CN_PALLET_1",
  CN_PALLET_2 = "CN_PALLET_2",
  CN_PALLET_3 = "CN_PALLET_3",
  ZA_PALLET_EUR_1 = "ZA_PALLET_EUR_1",
  ZA_PALLET_EUR_3 = "ZA_PALLET_EUR_3",
  ZA_PALLET_EUR_6 = "ZA_PALLET_EUR_6",
  CARTON = "CARTON",
  CRATE = "CRATE",
  DRUM = "DRUM",
  TANK = "TANK",
  ROLL = "ROLL",
  SKELETON_BOX = "SKELETON_BOX",
}

export enum FullCargoPackageType {
  CTR_STD_20 = "CTR_STD_20",
  CTR_STD_40 = "CTR_STD_40",
  CTR_CUBE_40 = "CTR_CUBE_40",
  CTR_CUBE_45 = "CTR_CUBE_45",
  CTR_REF_20 = "CTR_REF_20",
  CTR_REF_40 = "CTR_REF_40",
  CTR_CUBEREF_40 = "CTR_CUBEREF_40",
  CTR_CUBEREF_45 = "CTR_CUBEREF_45",
}

export type PackageTypeCodes = FullCargoPackageType | LooseCargoPackageType;

export interface PackageType<T = PackageTypeCodes> {
  CODE: T;
  LABEL: string;
  LABEL_WEBLATE_KEY: string;
  PRODUCT: string;
  SERVICE: string;
  REGION: string;
  COUNTRY_CODE: string;
  ICON: PackageTypeIcon;
  __typename: "PackageTypesData";
}

export interface Service {
  code: string;
  euOnly: string;
  product: Product;
  label: string;
  labelWeblateKey: string;
  iconName: string;
  isSelectable: boolean;
  __typename: "ProductService";
}

export interface ReferenceType {
  CODE: string;
  LABEL_WEBLATE_KEY: string;
  LABEL: string;
  __typename: "ReferenceTypesData";
}

export interface CommodityGroup {
  CODE: null | string;
  DESCRIPTION: null | string;
  DESCRIPTION_WEBLATE_KEY: null | string;
  ICON: null | string;
  INFORMATION: null | string;
  INFORMATION_HEADER: null | string;
  INFORMATION_HEADER_WEBLATE_KEY: null | string;
  LABEL: null | string;
  LABEL_WEBLATE_KEY: null | string;
  ORDER: null | string;
  PARENT: null | string;
  SUB_LEVEL_LABEL: null | string;
  SUB_LEVEL_LABEL_WEBLATE_KEY: null | string;
  TEMP_CONTROL: null | string;
  TEMP_MAX: null | string;
  TEMP_MIN: null | string;
  TEMP_UOM: null | string;
  INFORMATION_WEBLATE_KEY: null | string;
  PRODUCT_CODE: null | string;
  SHOW_DETAILS: boolean;
  __typename: "CommodityGroup";
}

export interface DangerousGoodsDivision {
  CODE: string;
  DESCRIPTION: string;
  DESCRIPTION_WEBLATE_KEY: string;
  CLASS_CODE: string;
  ORDER: string;
  __typename: "DangerousGoodsDivisionData";
}

export interface DangerousGoodsClass {
  CODE: string;
  DESCRIPTION: string;
  DESCRIPTION_WEBLATE_KEY: string;
  ORDER: string;
  __typename: "DangerousGoodsClassesData";
}

export interface DangerousGoodsPackagingGroup {
  CODE: string;
  DESCRIPTION: string;
  DESCRIPTION_WEBLATE_KEY: string;
  ORDER: string;
  __typename: "DangerousGoodsPackagingGroupsData";
}

export const countryCodeExceptions = [{ defaultCode: "DX", newCode: "AE" }];

export interface DocumentType {
  CODE: string;
  EIP_TYPE: string;
  PRODUCT_CODE: string;
  LABEL: string;
  LABEL_WEBLATE_KEY: string;
  ORDER: string;
  __typename: "DocumentTypesData";
}

export interface ContainerDescription {
  CODE?: FullCargoPackageType;
  SYSTEM_OF_MEASUREMENT?: UnitSystem;
  GROSS_WEIGHT: number;
  NET_WEIGHT: number;
  TARE_WEIGHT: number;
  __typename: "ContainerDescription";
}

export const qnbServiceToMdmService: Partial<
  Record<AirFreightService, ServiceLevel>
> = {
  [AirFreightService.AEB]: ServiceLevel.ECONOMY,
  [AirFreightService.AEC]: ServiceLevel.ECONOMY,
  [AirFreightService.AEM]: ServiceLevel.ECONOMY,
  [AirFreightService.ACB]: ServiceLevel.CONNECT,
  [AirFreightService.ACC]: ServiceLevel.CONNECT,
  [AirFreightService.ACM]: ServiceLevel.CONNECT,
  [AirFreightService.APB]: ServiceLevel.PRIORITY,
  [AirFreightService.APC]: ServiceLevel.PRIORITY,
  [AirFreightService.APD]: ServiceLevel.PRIORITY,
  [AirFreightService.APM]: ServiceLevel.PRIORITY,
  [AirFreightService.PLE]: ServiceLevel.AFP_ECONOMY,
  [AirFreightService.PLP]: ServiceLevel.AFP_PRIORITY,
};

export const mdmServiceToQnService: Partial<
  Record<ServiceLevel, AirFreightService[]>
> = {
  [ServiceLevel.ECONOMY]: [
    AirFreightService.AEB,
    AirFreightService.AEC,
    AirFreightService.AEM,
  ],
  [ServiceLevel.CONNECT]: [
    AirFreightService.ACB,
    AirFreightService.ACC,
    AirFreightService.ACM,
  ],
  [ServiceLevel.PRIORITY]: [
    AirFreightService.APB,
    AirFreightService.APC,
    AirFreightService.APD,
    AirFreightService.APM,
  ],
  [ServiceLevel.AFP_ECONOMY]: [AirFreightService.PLE],
  [ServiceLevel.AFP_PRIORITY]: [AirFreightService.PLP],
};

export enum UnitSystem {
  METRIC = "METRIC",
  IMPERIAL = "IMPERIAL",
}
